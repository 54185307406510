
export const english = {
    BUTTON_LANG_EN: 'English',
    BUTTON_LANG_ZH: '中文',
    BUTTON_LANG_FR: 'Français',
    BUTTON_LANG_DD: 'See text names',

    EDIT_GLOBAL_PUSH_CONFIG: 'Edit Default Push Config',

    // Navigation
    NAV_USER_SETTINGS: 'User Settings',
    NAV_RETURN_TO: 'Return to {originalUser}',
    NAV_SOFTWARE_INFO: "License information",

    RETURN_TO_OLD_USER: 'Return to Old user',
    NAV_DEVICES_ITEM: 'Devices',
    NAV_MANAGE_GROUPS: 'Groups',
    NAV_MAP: 'Map',

    NAV_GATEWAYS_ITEM: 'Gateways',
    NAV_GATEWAYS_LIST_GATEWAYS: 'Manage Gateways',
    NAV_GATEWAY_TRACE: "View Network Trace",

    NAV_APPLICATIONS_ITEM: 'Applications',

    NAV_LOGINS_ITEM: 'Login',
    NAV_USERS_ITEM: 'Users',
    NAV_LOGINS_LIST_USERS: 'Manage User Logins',
    NAV_USERS_LIST_USERS: 'Manage Users Accounts',
    NAV_ORGANIZATION_LIST_USERS: 'Manage Organisation',

    NAV_CUSTOMERS_ITEM: 'Customers',
    NAV_CUSTOMERS_LIST_CUSTOMERS: 'Manage Customers',
    NAV_DASHBOARD: 'Dashboard',
    NAV_SYSTEM: 'System',

    NAV_HELP: 'Help',
    NAV_FUOTA_CAMPAIGNS_ITEM: 'FUOTA Campaigns',

    NAV_SWITCH_TO_NST: "Switch to NST",


    NAV_JOIN_SERVERS: "Join Servers",
    NAV_GLOBAL_JOIN_SERVERS: "Global Join Servers",

    NAV_NST_GATEWAYS: "Gateways",
    NAV_NST_DEVICES: "Devices",
    NAV_NST_SERVERS: "Servers",
    NAV_NST_ALARMS: "Alarms",
    NAV_NST_GATEWAY_ALARMS: "Gateway Alarms",
    NAV_NST_SERVER_ALARMS: "Server Alarms",

    NAV_NST_TENANTS: "Tenants",
    NAV_NST_AUDIT_TRAIL: "Audit Trail",
    NAV_NST_AUDIT_TRAIL_GATEWAYS: "Gateway Audit Trail",

    NAV_NST_JOBS: "Jobs",
    NAV_NST_ANALYTICS: "Analytics",
    NAV_NST_GATEWAY_REPORT: "Gateway Report",

    NAV_NST_GENERAL_SETTINGS: "General settings",
    NAV_NST_SERVER_VERSIONS: "Server version",
    NAV_NST_SWITCH_TO_APP: "Switch to APP",

    NAV_NST_PENDING_GATEWAYS: "Pending Gateways",


    MAP_DEVICES: "Devices",
    MAP_GATEWAYS: "Gateways",


    // Welcome
    WELCOME_LEARN_MORE: 'Learn More',

    // My Apps
    MY_APPS_ADD_APP: 'Add Application',
    MY_APPS_APP: 'Application',
    MY_APPS_ACTION_EDIT: 'Edit App',
    MY_APPS_ACTION_DELETE: 'Delete App',
    MY_APPS_ACTION_BULK_DELETE: 'Delete Apps',
    MY_APPS_STOP_DEFAULT_PUSH: "Stop Default Push",
    MY_APPS_START_DEFAULT_PUSH: "Start Default Push",


    APP_ID: 'App ID',
    UUID: 'UUID',

    // My devices
    MY_DEVICES_TITLE: 'Devices',
    MY_DEVICE_SUSPEND: 'Suspend Device',
    MY_DEVICE_UNSUSPEND: 'Unsuspend Device',
    MY_DEVICES_DETAIL: 'Device Detail',
    MY_DEVICES_ACTION_SEND: 'Send to device',
    MY_DEVICES_ACTION_DATA: 'View Data',
    MY_DEVICES_ACTION_EDIT_DEVICES: 'Edit Devices',
    MY_DEVICES_ACTION_DELETE_DEVICES: 'Delete Devices',
    MY_DEVICES_ACTION_DELETE: 'Delete Device',
    MY_DEVICES_DATA: "Device Data",

    MY_DEVICES_APPLICATIONS: "Apps",
    MY_DEVICES_GROUPS: "Groups",
    GROUP_ASSIGNMENT: "Group Assignment",
    APP_ASSIGNMENT: "App Assignment",
    EDIT_GROUP: "Group Info",
    DELETE_GROUP: "Delete Group",
    SEND_MULTICAST: "Send Multicast",
    GROUP_ID: "Group ID",
    GROUP_TITLE: "Title",
    ADD_GROUP: "Group",

    // Network Activity

    // Group Activity
    GROUP_ACTIVITY_TITLE: "Group Activity",

    // Applications
    MY_APPLICATIONS_TITLE: "Applications",
    APPLICATIONS_ADD: "Add Application",

    // Find Devices
    FIND_DEVICES_TITLE: "Find Devices",

    // Find Users
    FIND_USERS_TITLE: "Find Users",
    FIND_USERS_TABLE_ACCOUNT_TYPE: "Account type",
    FIND_USERS_TABLE_ADMINISTRATOR: "Administrator",
    FIND_USERS_TABLE_REGISTRATION_RIGHTS: "Registration Rights",
    FIND_USERS_TABLE_PARENT: "Parent",


    // Batch Registration
    ERROR: "Error",
    BATCH_REGISTER_DEVICES_ERROR1: 'There is an empty field at column {column_num}',
    BATCH_REGISTER_DEVICES_ERROR2: 'Missing some of the mandatory fields DevEUI, device_profile_uuid and service_profile_uuid in the header',
    BATCH_REGISTER_DEVICES_SERVER_ERROR: 'Registering the device on row {index} failed because of - {message_error}',
    BATCH_UPDATE_DEVICES_SERVER_ERROR: 'Updating the device on row {index} failed because of - {message_error}',
    BATCH_ERROR_REGISTERING: 'Error registering devices',
    BATCH_ERROR_UPDATE: 'Error updating devices',
    BATCH_REGISTER_DEVICES_MISSING_REQUIRED_FIELDS: 'Mandatory field {field_name} is missing.',

    // Data
    GROUP_MULTICASTS_MESSAGE: 'There {number, plural, =0{are no multicasts} one{is 1 multicast} other{are # multicasts}} for group: ',
    DATA_DELETE_PACKET: 'Delete Packet',
    DATA_DELETE_PACKETS: 'Delete Packets',

    DIRECTION_SHORT: 'Direction',

    PACKET_STATUS_0: 'Enqueued',
    PACKET_STATUS_1: 'Sent, waiting Ack',
    PACKET_STATUS_1_1: 'Sent',
    PACKET_STATUS_2: 'Ack Ok',
    PACKET_STATUS_3: 'Ack Fail',
    PACKET_STATUS_4: 'Error',
    PACKET_STATUS_5: 'Cancelled',

    TIME: 'Time',
    FCNT: 'FCNT',
    PORT: 'Port',
    DATA_RATE: 'Data Rate',
    DATA: 'Data',
    RSSI: 'RSSI',

    // Send Data Modal

    // Gateway Related
    GATEWAYS_TITLE: 'Gateways',
    GATEWAY_ID: 'Gateway ID',
    GATEWAY_NAME: 'Gateway Name',
    VIEW_GATEWAY: 'Gateway Location',
    DELETE_GATEWAY: 'Delete Gateway',
    DELETE_GATEWAYS: 'Delete Gateways',
    ADD_GATEWAY: 'Add Gateway',

    GATEWAY_STATUS_ANY: 'Any',

    // Modal Add Gateway

    // Map
    MAP_FILTER_DEVICES: 'Filter devices',
    MAP_FILTER_GATEWAYS: 'Filter gateways',
    MAP_SHOW_DEVICES: 'Show Devices',
    MAP_SHOW_GATEWAYS: 'Show Gateways',
    MAP_MOVABLE: 'Movable',
    MAP_REFRESH_GATEWAYS: "Refresh Gateway",
    MAP_GATEWAY_TABLE_NAME: "Name",
    MAP_GATEWAY_TABLE_ID: "ID",
    MAP_GATEWAY_TABLE_STATUS: "Status",

    MAP_GATEWAY_TABLE_STATUS_LABEL: "Status:",
    MAP_PANEL_TAB_GATEWAYS: "Gateways",
    MAP_PANEL_TAB_DEVICES: "Devices",

    // Position on map modal

    // Gateway Configuration
    GATEWAY_CONFIGURATION: 'Gateway Info',
    GATEWAY_TYPE: 'Gateway type',

    // Customers
    ADD_CUSTOMER: 'Add Customer',
    CUSTOMER: 'Customer',
    EDIT_CUSTOMER: 'Edit Customer',
    ADMINISTRATOR: 'Administrator',

    // Edit Device Modal

    OWNER: 'Owner',

    ADD_DEVICE_BTN: 'Add Device',
    DEVICE_BTN: 'Device',
    FUOTA_BTN:'FUOTA Campaign',
    EXPORT_DEVICE_BTN: 'Export',
    IMPORT_DEVICE_BTN: 'Import',
    ADD_FUOTA_CAMPAIGN_BTN: 'FUOTA Campaign',
    

    // Payload Export for Device View and Payload View
    YOU_ARE_ABOUT_TO_USE_EXPORT_PAYLOADS_FOR: 'You are about to export payloads for',
    WHICH_MAY_RESULT_IN_A_VERY_LARGE_DOWNLOAD: 'which may result in a very large download',
    DEVICE: 'device',
    ALL_DEVICES: "all devices",
    PLEASE_LIMIT_THE_EXPORT_TO_THE_RELEVANT_TIME_RANGE: 'Please limit the export to the relevant time range',
    SELECTED_DEVICES: "selected device's",

    // HTTP Push config
    HTTP_PUSH_START: 'Start Push',
    HTTP_PUSH_STOP: 'Stop Push',

    // Warnings

    // Add user modal

    // Messages
    MSG_DEVICE_UPDATE_SUCCESS_BODY: 'Device {deveui} successfully updated',
    MSG_DEVICE_UPDATE_FAIL_BODY: 'Failed to update device. "{data}"',
    MSG_GATEWAY_POS_UPDATE_SUCCESS: 'Gateway successfully updated',
    MSG_GATEWAY_POS_UPDATE_FAIL: 'Failed to update position',

    // Add User modal

    // Edit User Modal

    GATEWAY: 'Gateway',

    // Manage App Modal

    // Users list
    USER_LIST_REGISTRATION_RIGHTS: 'Registration Rights',
    USER_LIST_DEVICE_COUNT: 'Registered Devices',

    // Organization list
    ORGANIZATION_LIST_ADD_ORGANIZATION: 'Add Organisation',
    ORGANIZATION: 'Organisation',
    ORGANIZATION_LIST_TITLE: 'Organisations',
    EDIT_ORGANIZATION: 'Edit Organisation',

    ADD_LOGIN: 'Add Login',
    ADD_USER: 'Add User',
    EDIT_LOGIN: 'Edit Login',
    LOGIN: 'Login',
    USER: 'User',
    EDIT_USER: 'Edit User',

    // Generic
    DEVEUI: 'DevEUI',
    COMMENT: 'Comment',
    STATUS: 'Status',
    LAST_SEEN: 'Last Seen',
    REFRESH_LIST: 'Refresh List',
    REFRESH_DEVICE_LIST: 'Refresh Device List',
    STOP_AUTO_REFRESH: 'Stop autorefresh',
    AUTO_REFRESH: 'Autorefresh',
    BLOCKED_GATEWAYS : 'Pending Gateways',
    DEFAULT_GATEWAYS_CONFIG : "Default Gateway LoraWAN radio configurations",
    MANAGE_GATEWAY_AUTHORIZATION: "Manage Gateway Authorization",
    GATEWAY_TAGS : "Gateway Tags",
    DEFAULT_GATEWAY_PARAMS : "Default Gateway Parameters",

    SIGN_IN: 'Sign in',
    SIGN_OUT: 'Sign out',
    USER_ID: 'User ID',
    PASSWORD: 'Password',

    PAYLOADS: 'Payloads',

    VIA: 'via {originalUser}',
    POWERED_BY: 'Powered by',
    WELCOME_RIGHT_MESSAGE: 'PARTNER FOR A SMARTER FUTURE',

    REFRESH: 'Refresh',
    REGISTER: 'Register',
    DELETE: 'Delete',
    UPDATE: 'Update',
    CLEAR: 'Clear',

    GTW_STATUS_NEVER_SEEN: 'Never Seen',
    GTW_STATUS_GTW_INIT: "Registered, never seen",
    GTW_STATUS_OK: 'OK',
    GTW_STATUS_OFF: 'Off',
    GTW_STATUS_BACKHAUL_ISSUE: 'Connection Problem',
    GTW_STATUS_OFF_OR_BACKHAUL_ISSUE: 'Off or Connection Problem',
    GTW_STATUS_RADIO_OFF: 'LoRa Radio Off',

    OK: 'OK',
    CANCEL: 'Cancel',
    YES: 'Yes',
    NO: 'No',
    DECRYPTED: 'Decrypted',
    CLICK_TO_UNLOCK: "Click to unlock",

    NAV_ADMIN_DEVICES: "Find Devices",
    NAV_ADMIN_USERS: "Find Users",

    // forgot password functionality
    FORGOT_PASSWORD: "Forgot your password?",
    FORGOT_USERNAME: "Forgot your username?",
    FORGOT_PASSWORD_INSTRUCTIONS: "Please enter your username below and we'll send you instructions to your email how to change your password",
    RETURN_TO_LOGIN: "Return to Log in",
    SET_PASSWORD: "Set password",
    SEND_RESET_PASSWORD_EMAIL: "Send Email",
    RESET_PASSWORD_WELCOME_USER: "Welcome!<br>Please set a password to get started",
    RESET_PASSWORD_MESSAGE: "Please set a new password",
    RESET_PASSWORD_TOO_SHORT: "Too short",
    RESET_PASSWORD_NOT_COMPLEX: "Missing mix case, numbers and/or special characters",
    RESET_PASSWORD_DOSNT_MATCH: "Not matching",
    CONFIRM_PASSWORD: "Confirm Password",

    EMAIL_USERNAME_INSTRUCTIONS_ORBIWISE: "An e-mail has been sent to you",
    INVALID_TOKEN: "The password reset link has expired, please make a new request for resetting your password",
    PASSWORD_RESET_FAILED: "Unable to reset password",

    // Email verification
    EMAIL_VERIFICATION_SUCCESSFUL: "Email succesfully verified",
    EMAIL_VERIFICATION_INVALID: "Invalid email verification link",
    EMAIL_VERIFICATION_EXPIRED: "Email verification link no longer valid",

    //password policies

    MAP_NO_POSITION_SET: "No position set",
    BATCH_REGISTER_MAX_DEVICES: "The number of devices is bigger that the allowed number of devices for the user",
    NAV_PROFILES_ITEM: "Profiles",
    NAV_DEVICE_PROFILES: "Device Profiles",
    NAV_SERVICE_PROFILES: "Service Profiles",
    NAV_CONNECTIVITY_PROFILES: "Connectivity Profiles",
    NAV_ROAMING_PROFILES: "Roaming Profiles",

    SERVICE_PROFILE: "Service Profile",

    CONNECTIVITY_PROFILE: "Connectivity Profile",
    LOGIN_FAILED: "Login Failed",
    LOGIN_BLOCKED_DUE_TO_TOO_MANY_ATTEMPTS: "Too many failed signin attempts, signin temporarely suspended. Please try again later.",

    ROAMING_PROFILE: "Roaming Profile",

    ACTION_ADD: "Add Profile",
    IMPORT_PROFILE: "Import Profile",

    ACTION_DELETE: "Delete",
    TABLE_PROFILE_NAME: "Profile Name",
    TABLE_IMAGE: "Image",
    TABLE_COMMENT: "Description",
    TABLE_UUID: "UUID",
    TABLE_LINK: "Link",
    TABLE_REGION: "Region",
    
    TABLE_NAME: "Name",
    TABLE_NST_GATEWAY: "Gateway",
    TABLE_NST_GATEWAY_ID: "Gateway ID",
    TABLE_NST_GATEWAY_HAS_BEEN_SEEN: "Seen",
    TABLE_NST_TENANT: "Tenant",
    TABLE_NST_TENANT_UUID: "Tenant UUID",
    TABLE_NST_NUM_ALARMS: "#Alarms",
    TABLE_NST_GATEWAY_LAST_CONNECTION_TIME: "Last connection time",
    TABLE_NST_GATEWAY_LAST_REBOOT_TIME: "Last reconnect time",
    TABLE_NST_GATEWAY_TYPE: "Gateway Type",
    TABLE_NST_GATEWAY_SW_VERSION: "Software Version",
    TABLE_NST_GATEWAY_BACKHAUL: "Backhaul",
    TABLE_NST_GATEWAY_CELLULAR_RSSI: "Cellular RSSI",
    TABLE_NST_GATEWAY_CELLULAR_CONNECTION: "Cellular Connection",
    
    TABLE_NST_DEVICE_COMMENT: "Device comment",
    TABLE_NST_DEVADDR: "DevAddr",
    TABLE_NST_SPREADING_FACTOR: "SF",
    TABLE_NST_LAST_RECEPTION_TIME: "Lst RX",
    TABLE_NST_LAST_RSSI: "Lst RSSI",
    TABLE_NST_LAST_SNR: "Lst SNR",
    TABLE_NST_MAIN_GATEWAY_ID: "Main Gateway",
    TABLE_NST_NUM_OF_GATEWAYS: "#Gtwys",
    TABLE_NST_MAIN_GATEWAY_NAME: "Main Gtwy NM",

    NST_SERVERS_STOP_SERVER: "Stop Server",
    NST_SERVERS_RESTART_SERVER: "(Re)Start Server",

    TABLE_NST_SERVER: "Server",
    TABLE_NST_IP_ADDRESS: "IP",
    TABLE_NST_METRICS: "Metrics",
    TABLE_NST_SERVER_STATUS: "Status",
    TABLE_NST_ALARM_STATUS: "Alarm Status",
    TABLE_NST_SERVER_STATE: "Server State",

    TABLE_NST_ALARM_TYPE: "Alarm Type",
    TABLE_NST_SERVER_ID: "Server ID",
    TABLE_NST_SERVER_TYPE:  "Server Type",
    TABLE_NST_START_DATE: "Start Date",
    TABLE_NST_END_DATE: "End Date",
    TABLE_NST_DATE: "Date",
    TABLE_NST_START_TIME: "Start Time",
    TABLE_NST_JOB_TYPE: "Type",
    TABLE_NST_DESCRIPTION: "Description",
    TABLE_NST_BATCH_ID: "Batch ID",
    TABLE_NST_OWNER: "Owner",
    TABLE_NST_REASON: "Reason",
    TABLE_NST_AUTHORIZATION_KEY: "Authorization key",
    TABLE_NST_ATTEMPTS: "Attempts",
    TABLE_NST_PARAMETERS: "Parameters", 
    TABLE_NST_VALUE: "Value",

    TABLE_NST_TIME: "Time",
    TABLE_NST_USER: "User",
    TABLE_NST_EVENT: "Event",
    TABLE_NST_CATEGORY: "Category",
    TABLE_NST_ADD_TAG_ACTION: "Tag",
    TABLE_NST_ADD_TAG_CATEGORY_ACTION: "Tag Category",
    TABLE_NST_DELETE_TAG_CATEGORY_ACTION: "Tag Category",

    TABLE_NST_DELETE_AUTHORIZE_REQUEST: "Delete Authorize Request",
    TABLE_NST_DELETE_AUTHORIZE_REQUESTS: "Delete Authorize Requests",


    NST_TENANT_CERTIFICATE_ACTION: "Certificate",
    TABLE_NST_ADD_PARAMETER_ACTION: "Add Parameter",



    
    DELETE_PROFILE_MODAL_TITLE: "Delete Profile",
    DELETE_PROFILE_MESSAGE_MODAL_BODY: "Are you sure you want to delete {ProfileName} profile?",

    PROFILE_EDIT_ACTION: "Edit Profile",
    PROFILE_DELETE_ACTION: "Delete Profile",

    DEVICE_PROFILE: "Device profile",
    IMPORT_DEVICE_PROFILE: "Import Device Profile",

    QOS_PROFILE: "QoS Profile",
    NAV_QOS_PROFILES: "QoS Profiles",
    NAV_CHANNEL_PROFILES: "Channel Profiles",
    CHANNEL_PROFILE: "Channel Profile",

    SELECT_PROFILE_PLACEHOLDER: "Select profile",

    //Static messages

    BATCH_MUST_BE_A_STRING: " must be a string",
    BATCH_FIELD: "Field",
    BATCH_INVALID_VALUE: "contains an invalid value.",
    BATCH_MUST_8: " must be an 8-byte identifier.",
    BATCH_ERROR_MUST_16BIT: " must be an 16-byte identifier.",
    BATCH_ERROR_MUST_32BIT: " must be an 32-bit identifier.",
    BATCH_VALID_VALIE_BETWEEN: " valid values are between ",
    BATCH_TO: " to",
    BATCH_VALID_IS_NOT_SUPPORTED: " is not supported",
    BATCH_MUST_BE_BOOLEN: " must be boolean",
    BATCH_CAN_ONLY_BE_A_NUMBER: "can only be a number",
    BATCH_ALLOWED_VALUES_FOR: "Allowed values for ",
    BATCH_ALLOWED_VALUES_FOR_IS: " are sf7, sf8, sf9, sf10, sf11 and sf12",
    BATCH_ALLOWED_VALUES_FOR_STATIC: " are static mobile indoor and outdoor or combination from static,indoor static,outdoor mobile,indoor mobile,outdoor",
    BATCH_OTAA_ABP: " can only have values OTAA or ABP",
    BATCH_0_9: " valid values are 0 to 100",
    BATCH_0: " can only be 0",
    BATCH_CAN_ONLY_VERSION: " can only be 1.1.1, 1.0.4, 1.0.3, 1.0.2, 1.0.1 or 1.0.0",
    BATCH_CAN_A_B: " can only be A or B",
    BATCH_CAN_EU_US_CH: " can be EU868, US902, China779, EU433, Australia915, China470, AS923 or INDIA",
    BATCH_ERROR_ROW: " Errors found on row ",
    BATCH_ERROR: " error",
    MAC_MSG: "MAC Messages",

    SHOW_DECODE_PAYLOAD_SHORT: "Decoded",
    SHOW_DATA: "Data",
    POSITION_ESTIMATES_SHORT: "Pos Est",
    DUPLICATE_HEADERS: "The csv file contains duplicate headers",

    // Linked Profiles
    NEW_LINKED_PROFILE: "Linked Profile",
    ADD_NEW_LINKED_PROFILE: "Create Linked Profile",
    BATCH_REGISTER_DEVICES_ERROR3: "The csv file must contain the mandatory properties for device with profiles",
    BATCH_WRONG_FORMAT: " format is not correct",
    UNLINK_PROFILE_MESSAGE_MODAL_BODY: "Are you sure you want to unlink {ProfileName} profile?",
    UNLINK_LINKED_PROFILE_MODAL_TITLE: "Unlink Profile",
    UNLINK_LINKED_PROFILE_ACTION: "Unlink Profile",
    UNLINK_PROFILE_MODAL_CONFIRM_BUTTON: "Unlink",

    // API Based Table

    TABLE_SHARED: "Shared",
    TABLE_SORRY_NO_DATA_AVAILABLE: "<b>Sorry!</b> No data available!",
    TABLE_SHOWING: "Showing {value}",
    TABLE_COUNT_ALL: "All",
    TABLE_COUNT_SELECTED: "Selected",
    TABLE_BUTTON_CLEAR_SELECTED: "Clear",
    TABLE_BUTTON_BULK_ACTIONS: "Bulk Actions",


    // Device Profiles Json Schema Form

    // Connectivity Profiles Json Schema Form

    // Roaming Profiles Json Schema Form

    // QoS Profiles Json Schema Form

    // Channel Profiles Json Schema Form

    TABLE_JOIN_EUI_RANGE: "Join EUI range",
    TABLE_JOIN_SERVER_NAME: "Name",
    TABLE_JOIN_SERVER_TYPE: "Type",
    TABLE_JOIN_SERVER_URL: "URL",

    ADD_JOIN_SERVER: "Join Server",


    // Bulk Delete Action
    TOO_MANY_REQ: "Too Many Requests",
    RETRY_AFTER: "Retry After",
    MINS: "minute(s)",
    STOP_DELETE: "Stopping the deletion process",
    BULK_OPERATION_STOPPED: "Bulk operation stopped",
    CERTAIN_SERIES_OF_BULK_ACTIONS_ARE_LIMITED_TIME: "Certain series of bulk actions are limited in time",
    THE_SUCCESSIVE: "The successive",
    BUT_YOU_WILL_NOT_BE_ABLE_TO: "but you will not be able to",
    THEM_AGAIN_UNTIL_AFTER: "them again until after",
    OF_WAITING: "of waiting",
    DELETING: "Deleting",
    REGISTERING: "registering",
    UPDATING: "Updating",
    WORKED: "Worked",
    USERS: "User(s)",
    CUSTOMERS: "Customer(s)",
    ORGANISATIONS: "Organisations(s)",
    NODES: "Device(s)",
    REGISTERED: "Registered",


    SUCCESSFULLY: "Successfully",
    DELETED: "Deleted",
    YOU_CAN: "You can",
    MORE: "more",
    HAS_BEEN_SUCCESSFULLY: "has been successfully",
    AFTER: "after",

    FIND: "Find",
    ACCOUNTS: "Accounts",
    NAV_SIGNED_AS: "Signed as",
    ADMIN_TYPE_CUSTOMER: "Customer Administrator",
    ADMIN_TYPE_USER: "User Administrator",
    ADMIN_TYPE_ORGANIZATION: "Organisation Administrator",
    TYPE_CUSTOMER: "Customer",
    TYPE_USER: "User",

    SUSPEND_DEVICES: "Suspend Devices",
    UNSUSPEND_DEVICES: "Unsuspend Devices",

    CHANGE_OWNER: "Change Owner",

    CREATABLE_SELECT_LABEL: "UUID",

    CAN_NOT_RETURN_TO_PARENT: "Return to action failed",

    NO_RIGHTS_TO_SEE_THIS_PAGE: 'The User does not have permission to see this page.',
    ALERT_MODAL_TITLE_TEXT: "Error",

    MANAGE_APPLICATIONS_NAV_TITLE: "Manage Applications",

    ACTIVE_CONNECTIONS_NAV_TITLE: "Active Connections",
    ACTIVE_CONNECTIONS_PAGE_TITLE: "Active Connections",
    ACTIVE_CONNECTIONS_TABLE_COL_TYPE: "Type",
    ACTIVE_CONNECTIONS_TABLE_COL_STATUS: "Status",
    ACTIVE_CONNECTIONS_TABLE_COL_CONN_TIME: "Connection Time",
    ACTIVE_CONNECTIONS_TABLE_COL_APPLICATION: "Application",
    ACTIVE_CONNECTIONS_TABLE_COL_REMOTE_ADDRESS: "Remote Address / Target URL",
    ACTIVE_CONNECTIONS_TABLE_COL_SUBSCRIPTIONS: "Subscriptions",
    ACTIVE_CONNECTIONS_TABLE_COL_ERRORS: "Errors",

    EVENT_LOG_NAV_TITLE: "Event Log",
    EVENT_LOG_PAGE_TITLE: "Event Log",
    EVENT_LOGS_TABLE_COL_TIMESTAMP: "Timestamp",
    EVENT_LOGS_TABLE_COL_EVENT: "Event",
    EVENT_LOGS_TABLE_COL_APPLICATION: "Application",
    EVENT_LOGS_TABLE_COL_TEXT: "Text",

    EVENT_LOGS_TABLE_LOG_LEVEL: "Log Level",


    RE_PUSH_PAYLOAD: "(Re-)Push Payload",

    PRODUCT_LICENSE_TITLE: "Product License",
    LORA_UPLINK_LOGS: "LoRa uplink logs",
    GET_SERVER_LOGS: "Server logs",

    // HELP
    NAV_HELP_GETTING_STARTED: "Getting started",
    NAV_HELP_PAGE: "Documentation",
    NAV_HELP_SWAGGER: "Swagger",
    NAV_HELP_SUPPORT_EMAIL: "Email support",
    COLUMNS: "Columns",

    TENANT_DELETE: "Delete Tenant",
    TENANTS_DELETE: "Delete Tenants",
    TENANT_ADD: "Add Tenant",
    TENANT_TITLE: "Tenant",
    TENANT_CERTIFICATE: "Certificate",
    TENANT_INFO: "Tenant Info",
    SEARCH_MAP: "Search Maps",
    FUOTA_CAMPAIGN: "FUOTA Campaigns",
    CAMPAIGN_NAME: "Name",
    CAMPAIGN_DESCRIPTION: "Description",
    CAMPAIGN_FIRMWARE: "Firmware",
    CAMPAIGN_DEVICES: "Devices",
    CAMPAIGN_GROUPS: "Groups",
    CAMPAIGN_STATUS: "Status",
    CAMPAIGN_TYPE: "Type",
    CAMPAIGN_STAGE: "Stage",
    CAMPAIGN_START_TIME: "Start time",
    CAMPAIGN_UPLOAD_DATE: "Upload Date",
    CAMPAIGN_END_TIME: "End time",
    MY_CAMPAIGNS_ACTION_START: "Campaign Start",
    MY_CAMPAIGNS_ACTION_STOP: "Campaign Stop",
    ADD_FUOTA_BTN: "FUOTA",
    MY_DEVICES_ADD_FUOTA_BTN: "Add to FUOTA campaign",
    FIRMWARE_FILE_SIZE: "File Size (bytes)"
};
