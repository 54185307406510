import React, { useState } from "react";
import { isMobile } from "react-device-detect";

// import { evalExpr } from "./SchemaTools";
import { ISchemaFormProps } from "./SchemaForm";
import { SchemaFormAceEdit } from "./SchemaFormAceEdit";

// Monaco
import Editor, { OnMount, loader, EditorProps } from '@monaco-editor/react';
import * as monaco from 'monaco-editor';
import { registerExtensionFormComponent } from "./SchemaController";


// To use the monaco npm version, the monaco-editor-webpack-plugin must be used in the webpack script. See
// more here:
// 		https://www.npmjs.com/package/monaco-editor-webpack-plugin
loader.config({ monaco });



export const SchemaFormMonacoEdit : React.FC<ISchemaFormProps> = (props) =>  {

	// The monaco editor doesn't support 
	if (isMobile) { return SchemaFormAceEdit(props); }
	
	const [value, setValue] = useState(props.value);
	const [context, setContext] = useState(0);
	const [update, setUpdate] = useState(0);

	const { args } = props;
	const { uiElem } = args;
	const componentOptions = uiElem?.componentOptions || {};
	const language = componentOptions.languageMode || "typescript";

	if (props.context != context) {
		setValue(props.value);
		setContext(props.context);
	}

	const onChange = (value: string, event: any) => {
		const text = props.onTextChange(value);
		if (text != null) {
			setValue(text);
		} else {
			setUpdate(update + 1);
		}
	}

	const onMount: OnMount = (editor, monaco) => {

		if (language === "typescript") {

			const compilerOptions = monaco.languages.typescript.typescriptDefaults.getCompilerOptions();
			// console.log({...compilerOptions});
			monaco.languages.typescript.typescriptDefaults.setCompilerOptions({
				...compilerOptions,
				target: monaco.languages.typescript.ScriptTarget.ES2020,
				noEmit: true,
				// lib: ["lib.d.ts"], // lib: ["es6"],
				// noLib: true,
			});
	

			monaco.languages.typescript.typescriptDefaults.setExtraLibs([]);
			if (componentOptions.monacoPreloadExpr) {
	
				// const { fullkey, value, error, elem, readOnly, } = args || {};
				// const libs = evalExpr(componentOptions.monacoPreloadExpr, lib, args.objects, { fullkey, value, error, readOnly, schema: elem })
	
				// if (libs && Array.isArray(libs)) {
				// 	for (const lb of libs) {
				// 		if (lb.filePath && lb.content) {
				// 			monaco.languages.typescript.typescriptDefaults.addExtraLib(lb.content, lb.filePath);
				// 		}
				// 	}
				// }
			}
	
			if (componentOptions.monacoPreload) {
				const src = componentOptions.monacoPreload;
				const url = "ts:filename/preload.d.ts";
				monaco.languages.typescript.typescriptDefaults.addExtraLib(src, url);
			}

		} else if (language === "json") {

			const schemas: any[] = [];
			if (componentOptions.monacoJsonSchema) {

				let schema: any = null;
				if (typeof componentOptions.monacoJsonSchema === "string") {
					
				} else if (typeof componentOptions.monacoJsonSchema === "object" && componentOptions.monacoJsonSchema.type === "object") {
					schema = componentOptions.monacoJsonSchema;
					schemas.push(componentOptions.monacoJsonSchema);

				} else {
					console.log("Invalid JSON schema")
				}

				if (schema) {
					schemas.push({ uri: "http://orbiwise.com/dynamic-json-schema.json", fileMatch: ["*"], schema })
				}

			}
			monaco.languages.json.jsonDefaults.setDiagnosticsOptions({ validate: true, schemas });

		}

	};

	const monacoProps: EditorProps = {
		className: "schema-engine-monaco-edit" + (componentOptions.className ? " " + componentOptions.className  : ""),

		theme: componentOptions.monacoTheme || "github",
		height: componentOptions.monacoHeight || componentOptions.height || "90vh",

		width: componentOptions.monacoWidth || componentOptions.width || "100%",
		language,
		value,
		onChange,
		onMount,

		options: {
			readOnly: args.readOnly,
		},
	}

	return <div dir="ltr"><Editor key={args.fullkey} {...monacoProps}/></div>;
};


registerExtensionFormComponent("monacoedit", SchemaFormMonacoEdit);
